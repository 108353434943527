import React, {createContext, useContext, useEffect, useState} from 'react';

import {authService} from '../../services/authService';
import {User} from '../../types/user';

interface AuthContextType {
    user: User | null;
    isAuthenticated: boolean;
    login: (username: string, password: string) => Promise<void>;
    logout: () => Promise<void>;
    me: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const initializeAuth = async () => {
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
                setUser(JSON.parse(storedUser));
            }
            setLoading(false);
        };
        initializeAuth();
        return () => {};
    }, []);

    const login = async (username: string, password: string) => {
        try {
            const {user, token} = await authService.login({username, password});
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('token', token??'');
            setUser(user);
        } catch (error) {
            console.log('Erro ao login:', error);
            throw error;
        }
    };

    const logout = async () => {
        try {
            await authService.logout();
        } catch (error) {
            console.log('Erro ao logout:', error);
        } finally {
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            setUser(null);
        }

    };

    const me = async () => {
        try {
            const user = await authService.me();
            localStorage.setItem('user', JSON.stringify(user));
            setUser(user);
        } catch (error) {
            console.log('Erro ao login:', error);
        }
    }

    const value = {
        user,
        isAuthenticated: user !== null,
        login,
        logout,
        me,
    };

    if (loading) {
        return <div>Loading...</div>; // Você pode substituir isso por um componente de carregamento adequado
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
