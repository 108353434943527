import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Spinner, Table, Tooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowDown, faLinkSlash } from "@fortawesome/free-solid-svg-icons";

import AxiosSingleton from "../../services/AxiosSingleton";
import { ProcessImportsInterface } from "../../types/ProcessImportInterface";

import DefaultHeader from "../../components/Hearders/DefaultHeader";

const MovementDownloadPage = () => {
    useEffect(() => {
        (async () => {
            try {
                const response = await AxiosSingleton.getInstance().get(`/api/process-import?type_process_import_id=2`);
                const { data } = response.data;
                console.log(data)

                let itemsReturned = [] as ProcessImportsInterface[];
                itemsReturned = data.map((item: any) => {
                    return {
                        id: item.id,
                        file: item.file,
                        typeUseWords: item.type_use_words,
                        createdAt: new Date(item.created_at),
                        batch: {
                            id: item.batch.id,
                            code: item.batch.code,
                            createdAt: new Date(item.batch.created_at),
                        },
                        processedAt: item?.processed_at ? new Date(item.processed_at) : null,
                        searchInitAt: item?.search_init_at ? new Date(item.search_init_at) : null,
                        searchEndAt: item?.search_end_at ? new Date(item.search_end_at) : null,
                        countCases: item.count_cases
                    } as ProcessImportsInterface;
                });

                setItems(itemsReturned);

            } catch (error) {
                console.error("Erro ao buscar importações:", error);
            }
        })()
    }, []);

    const [items, setItems] = useState<ProcessImportsInterface[]>([]);

    return (
        <>
            <DefaultHeader />
            <Container>
                <Row>
                    <Col>
                        <div className="d-flex align-items-end mt-3">
                            <h2 className="my-0 me-2 text-uppercase">Resultado:</h2> Movimentação e Capa
                        </div>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <Table hover striped responsive>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Lote</th>
                                    <th className="text-center">Cadastro</th>
                                    <th className="text-center">Processamento</th>
                                    <th className="text-center">Inicio da Busca</th>
                                    <th className="text-center">Fim da Busca</th>
                                    <th className="text-end">Quantidade Casos</th>
                                    <th className="text-center">Opções</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    items.length === 0
                                    ? <tr><td className="text-center" colSpan={4*2+1}>No Elements</td></tr>
                                    : items.map(
                                        (item: ProcessImportsInterface) => <RowTable
                                            key={item.batch.code}
                                            id={item.id}
                                            batch={item.batch}
                                            createdAt={item.createdAt}
                                            processedAt={item.processedAt}
                                            searchInitAt={item.searchInitAt}
                                            searchEndAt={item.searchEndAt}
                                            countCases={item.countCases}
                                        />)
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

const RowTable: React.FC<any> = ({
    id,
    batch,
    createdAt,
    processedAt,
    searchInitAt,
    searchEndAt,
    countCases
}) => {
    const optionsMinute = { hour12: false, hour: '2-digit', minute: '2-digit' };

    const [tooltipOpenOriginal, setTooltipOpenOriginal] = useState(false);
    const [tooltipOpen, setTooltipOpen]                 = useState(false);

    const toggleOriginal = () => setTooltipOpenOriginal(!tooltipOpenOriginal);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    return (
        <>
            <tr>
                <th>{id}</th>
                <td>{batch.code}</td>
                <td className="text-center">
                    {`${createdAt?.toLocaleDateString('pt-BR') ?? ''} ${createdAt?.toLocaleTimeString('pt-BR', optionsMinute) ?? ''}`}
                </td>
                <td className="text-center">
                    {`${processedAt?.toLocaleDateString('pt-BR') ?? ''} ${processedAt?.toLocaleTimeString('pt-BR', optionsMinute) ?? ''}`}
                </td>
                <td className="text-center">
                    {`${searchInitAt?.toLocaleDateString('pt-BR') ?? ''} ${searchInitAt?.toLocaleTimeString('pt-BR', optionsMinute) ?? ''}`}
                </td>
                <td className="text-center">
                    {`${searchEndAt?.toLocaleDateString('pt-BR') ?? ''} ${searchEndAt?.toLocaleTimeString('pt-BR', optionsMinute) ?? ''}`}
                </td>
                <td className="text-end">{countCases}</td>
                <td className="text-center">
                    <ButtonDownload id={id} batch={batch.code} type="original" isFinalized={!!searchEndAt} />
                    <ButtonDownload id={id} batch={batch.code} type="processed-movement" isFinalized={!!searchEndAt} />
                </td>
            </tr>
            <Tooltip
                isOpen={tooltipOpenOriginal}
                target={`btn-download-original-${id}`}
                toggle={toggleOriginal}
                placement={'top'}
            >
                <>Download Planilha Original</>
            </Tooltip>
            <Tooltip
                isOpen={tooltipOpen}
                target={`btn-download-${id}`}
                toggle={toggle}
                placement={'top'}
            >
                <>Download Prints</>
            </Tooltip>
        </>
    );
};

const ButtonDownload = ({ id, batch, type = "original", isFinalized = false }: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError]     = useState(false);
    const [isDownloadDisabled]      = useState(!(type === 'original' || isFinalized));

    const handleDownload = async (id: number, batch: string, typeDownload: string) => {
        try {
            setIsLoading(true);
            const response  = await AxiosSingleton.getInstance().get(`/api/process-import/download-import/${typeDownload}/${id}`, {responseType: 'blob'});
            const url       = window.URL.createObjectURL(new Blob([response.data]));
            const link      = document.createElement('a');

            link.href = url;
            link.setAttribute('download', typeDownload === 'original' ? `original-${batch}.xlsx` : `processed-${batch}.zip`);
            document.body.appendChild(link);

            link.click();
            link.remove();
        } catch (error) {
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Button
                id={type === 'original' ? `btn-download-original-${id}` : `btn-download-${id}`}
                className={`me-1 ${ isError || isLoading || isDownloadDisabled ? 'disabled' : ''}`}
                size="sm"
                color={ isError ? 'danger' : type === 'original' ? 'primary' : 'warning'}
                onClick={() => handleDownload(id, batch, type)}
                disabled={isError || isLoading || isDownloadDisabled}
                outline={isError}
            >
                {
                    isLoading? <Spinner size={'sm'} /> : <FontAwesomeIcon icon={isError ? faLinkSlash : faCloudArrowDown} />
                }
            </Button>
        </>
    );
}

export default MovementDownloadPage;
