import ProtectedRoute from "./components/ProtectedRoute";
import BatchesSearchesPage from "./pages/BatchesSearches/BatchesSearchesPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ForgotPasswordPage/ResetPasswordPage";
import HomePage from "./pages/Home/HomePage";
import LoginPage from "./pages/Login/LoginPage";
import MovementPage from "./pages/Movement/MovementPage";
import MovementDownloadPage from "./pages/MovementDownload/MovementDownloadPage";
import NotFoundPage from "./pages/NotFound/NotFoundPage";
import ProcessImportPage from "./pages/ProcessImport/ProcessImportPage";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import BradescoReport from "./pages/Reports/BradescoReport/BradescoReport";

export const routes= [
    {
        path: "/login",
        element: <LoginPage />,
    },
    {
        path: "/forgot-password",
        element: <ForgotPasswordPage />,
    },
    {
        path: "/reset-password",
        element: <ResetPasswordPage />,
    },
    {
        path: "*",
        element: <NotFoundPage />,
    },
    {
        path: "/",
        element: (
            <ProtectedRoute>
                <HomePage />
            </ProtectedRoute>
        ),
    },
    {
        path: "/users/profile",
        element: (
            <ProtectedRoute>
                <ProfilePage />
            </ProtectedRoute>
        ),
    },
    {
        path: "/search-config",
        element: (
            <ProtectedRoute>
                <ProcessImportPage />
            </ProtectedRoute>
        ),
    },
    {
        path: "/movements",
        element: (
            <ProtectedRoute>
                <MovementPage />
            </ProtectedRoute>
        ),
    },
    {
        path: "/movements-download",
        element: (
            <ProtectedRoute>
                <MovementDownloadPage />
            </ProtectedRoute>
        ),
    },
    {
        path: "/batches-searches",
        element: (
            <ProtectedRoute>
                <BatchesSearchesPage />
            </ProtectedRoute>
        ),
    },
    {
        path: "/reports/bradesco",
        element: (
            <ProtectedRoute>
                <BradescoReport />
            </ProtectedRoute>
        ),
    },
];